import request from '@/utils/request';
export function auitPrint(ids) {
  return request({
    url: '/shop-audit/audit/print',
    method: 'post',
    params: {
      ids: ids
    }
  });
}
export function auditPass(id, comment, data) {
  return request({
    url: '/shop-audit/audit/pass',
    method: 'post',
    params: {
      id: id,
      comment: comment
    },
    data: data
  });
}
export function auditReject(id, comment, data) {
  return request({
    url: '/shop-audit/audit/reject',
    method: 'post',
    params: {
      id: id,
      comment: comment
    },
    data: data
  });
}
export function auditSteps(id) {
  return request({
    url: '/shop-audit/audit/steps',
    method: 'get',
    params: {
      id: id
    }
  });
}
export function auditRecordChange(id) {
  return request({
    url: '/shop-audit/audit/record-change',
    method: 'get',
    params: {
      id: id
    }
  });
}
export function similarShopQuery(data) {
  return request({
    url: '/shop-audit/similar-shop/query',
    method: 'post',
    data: data
  });
}
export function detailWithSpecifiedData(data) {
  return request({
    url: '/shop/detail-with-specified-data',
    method: 'post',
    data: data
  });
}
export function auditCancel(id) {
  return request({
    url: '/shop-audit/audit/cancel',
    method: 'post',
    params: {
      id: id
    }
  });
}
export function gmGetShopTypeListByChaiGroupAndOrg(chaingroup, orgId) {
  return request({
    url: '/shop/get-shoptype-list-by-chaingroup-and-org',
    method: 'get',
    params: {
      chaingroup: chaingroup,
      orgId: orgId
    }
  });
}