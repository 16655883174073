var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "sticky",
        [
          _c("el-page-header", {
            attrs: { title: _vm.$t("button.back"), content: _vm.getTitle() },
            on: { back: _vm.close },
          }),
          _vm._v(" "),
          _c("hr"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("GeneralEditForm", {
            ref: "GeneralEditForm",
            attrs: {
              columns: _vm.editColumns,
              "edit-form": _vm.editForm,
              rules: _vm.rules,
              "read-only": true,
            },
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
              _vm._v(
                "\n       " + _vm._s(_vm.$t("label.auditSteps")) + "\n     "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-steps",
                  {
                    attrs: {
                      direction: "vertical",
                      active: _vm.activeStep,
                      "finish-status": "success",
                      space: "50px",
                    },
                  },
                  _vm._l(_vm.auditStepsData, function (step) {
                    return _c(
                      "el-step",
                      {
                        key: step.b_audit_step_name,
                        attrs: {
                          status: _vm.getAuditStepStatus(step.b_audit_result),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: { color: "#303133" },
                            attrs: { slot: "title" },
                            slot: "title",
                          },
                          [
                            _vm._v(
                              "\n             " +
                                _vm._s(step.b_audit_step_name) +
                                "\n           "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#909399",
                              "margin-bottom": "20px",
                              "font-size": "14px",
                            },
                            attrs: { slot: "description" },
                            slot: "description",
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n             " +
                                  _vm._s(step.b_audit_user_name) +
                                  " " +
                                  _vm._s(_vm.getLocalTime(step.b_audit_time)) +
                                  "\n             "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { color: "#E6A23C" } }, [
                              _c("pre", [
                                _vm._v(_vm._s(step.b_audit_comments)),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          !this.auditFinished && this.viewType == 1
            ? _c("div", { staticStyle: { width: "50%" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "20px",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _vm._v(
                      "\n       " +
                        _vm._s(_vm.$t("label.auditComment")) +
                        "\n     "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", autosize: { minRows: 3 } },
                      model: {
                        value: _vm.comment,
                        callback: function ($$v) {
                          _vm.comment = $$v
                        },
                        expression: "comment",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "audit_buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "filter-item",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.pass()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n         " +
                            _vm._s(_vm.$t("button.pass")) +
                            "\n       "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "filter-item",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.reject()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n         " +
                            _vm._s(_vm.$t("button.reject")) +
                            "\n       "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !this.auditFinished && this.activeStep <= 1 && this.viewType == 3
            ? _c("div", { staticStyle: { width: "50%" } }, [
                _c(
                  "div",
                  { staticClass: "audit_buttons" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [{ name: "waves", rawName: "v-waves" }],
                        staticClass: "filter-item",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.cancel()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n         " +
                            _vm._s(_vm.$t("button.cancel")) +
                            "\n       "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }